import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes ,useNavigate} from 'react-router-dom'
import './scss/style.scss'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// pages
const Login = React.lazy(() => import('./pages/Login'))
//const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'))
//const baseUrl =


//global.baseUrl = 'https://testemr-api-php.cb-dev.in/api';
//global.baseUrl = 'https://emr-api.healthconnectpro.in/api';

global.logoutHandler = (navigate) => {
  sessionStorage.clear();
  navigate('/');
};

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
            <Route exact path="/ResetPassword" element={<ResetPassword />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
